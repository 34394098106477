import React, { Component } from 'react'
import './Aboutus.scss'
export default class Aboutus extends Component {
    render() {
        return (
            <div id="about" className="about_us_main_container">
                <h1>About Us</h1>
                <p>
                AD 2020 brought to its people the tastiest crumbs from the many million kitchen laboratories locked up in the lockdown. While homecooks and bakers took their unique tastes out of their laboratories into the wide gut of the world, many were struggling to find the balance between whisking and packing all at the same time. That's when the timer clinked for us! 
Our business equips new age and upcoming foodepreneurs to single-handedly and effortlessly grow their businesses with the rights tools.
                </p>
            </div>
        )
    }
}
