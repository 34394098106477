import React, { Component } from 'react'
import './Navbar.scss'
import navbar_logo from '../../Assets/navbar_logo.svg'
export default class Navbar extends Component {
    render() {
        return (
            <div className="navbar_main_container">
                <div className="navbar_contents">
                    <div className="image_div">
                       
                    </div>
                    <img src={navbar_logo} alt="navbar_logo" />
                    <div className="links_div">
                        <ul>
                            <li>
                                <a href="#home">Home</a>
                            </li><li>
                                <a href="#about">About Us</a>
                            </li><li>
                                <a href="#vision">Vision</a>
                            </li><li>
                                <a href="#contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
