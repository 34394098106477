import React, { Component } from 'react'
import './Observe_plan_execute.scss'
import observe from '../../Assets/observe.svg'
export default class Observe_plan_execute extends Component {
    render() {
        return (
            <div id="home" className="Observe_plan_execute_main_container">
                <div className="contents">
                    <div className="text_img">
                    <p>
                        Observe.
                        Plan.
                        Execute.
                    </p>
                    <img src={observe} alt="observe" />
                    </div>
                </div>
                {/* <div className="color_container">

                </div> */}
            </div>
        )
    }
}
