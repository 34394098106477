import React, { Component } from 'react'
import './ContactUs.scss'
export default class ContactUs extends Component {
    render() {
        return (
            <div id="contact" className="contact_us_main_container">
                <h1>Contact Us</h1>
                <div className="input_div">
                    <input type="text" placeholder="Full Name" />
                    <input type="text" placeholder="Email" />
                    <textarea id="description" name="description" rows="4" cols="50" placeholder="Description" />
                    <button>Send Message</button>
                </div>
                <div className="color_container">

                </div>
            </div>
        )
    }
}
