import React, { Component } from 'react'
import './Our_vision.scss'
export default class OurVision extends Component {
    render() {
        return (
            <div id="vision" className="our_vision_main_container">
                <h1>Our Vision</h1>
                <p>
                    Our vision is to build scalable solutions for small businesses that would help them grow exponentially.
                </p>
            </div>
        )
    }
}
