import './App.css';
import Landingpage from './Components/Landingpage/Landingpage';

function App() {
  return (
    <div className="App">
      <Landingpage />
    </div>
  );
}

export default App;
