import React, { Component } from 'react'
import './Footer.scss'
import {FaLinkedin, FaFacebookSquare, FaTwitterSquare, FaInstagram} from 'react-icons/fa'
export default class Footer extends Component {
    render() {
        return (
            <div className="footer_main_container">
                <p>©2021 Zabr Technologies Pvt Ltd</p>
                <div className="socila_meida_icons">
                    <ul>
                        <a href="https://www.linkedin.com/company/zabr-technologies"><li> <FaLinkedin /> </li></a>
                        <a href="https://www.facebook.com/Zabr-Technologies-105898281630355"><li> <FaFacebookSquare /> </li></a>
                        <a href="https://twitter.com/ZabrTechnologi1"><li> <FaTwitterSquare /> </li></a>
                        <a href="https://www.instagram.com/zabrtechnologies/"><li> <FaInstagram /> </li></a>
                    </ul>
                </div>
            </div>
        )
    }
}
