import React, { Component } from 'react'
import Aboutus from '../AboutUs/Aboutus'
import ContactUs from '../Contact_us/ContactUs'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Observeplanexecute from '../Observe_plan_execute/Observe_plan_execute'
import OurVision from '../OurVision/Our_vision'
import './Landingpage.scss'
export default class Landingpage extends Component {
    render() {
        return (
            <div className="main_container">
                <Navbar />
                <Observeplanexecute />
                <Aboutus />
                <OurVision />
                <ContactUs />
                <Footer />
            </div>
        )
    }
}
